import React from "react"
import ReactMaterialComponentBase from "../../Shared/ReactMaterialComponentBase"
import { RecipeCard } from "../React/RecipeCard"

export default class ViewRecipe extends ReactMaterialComponentBase {
  static get observedAttributes() {
    return ["target"]
  }

  constructor() {
    super()

    /**
     * @type {import("../Types").Recipe | null}
     */
    this.recipe = null

    this.jsxRootComponent = () => {
      if (!this.recipe) {
        return null
      }

      return (
        <RecipeCard
          recipeSelected={() => {}}
          recipe={this.recipe}
          updateRecipe={(recipe) => this.updateRecipe(recipe)}
        />
      )
    }

    this.target = this.getAttribute("target")

    this.getRecipe()
  }

  async attributeChangedCallback(name, oldValue, newValue) {
    if (name !== "target") {
      return
    }

    if (!newValue) {
      return
    }

    this.target = newValue

    await this.getRecipe()
  }

  async getRecipe() {
    if (!this.target) {
      return
    }

    try {
      const token = await window.authorise()

      const getRecipesResponse = await fetch(this.target, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      })

      const recipe = /** @type {import("../Types").Recipe} */ (await getRecipesResponse.json())

      this.recipe = recipe

      this.render()
    } catch (error) {
      this.onError && this.onError(error)
    }
  }

  async updateRecipe(recipe) {
    try {
      const token = await window.authorise()

      await fetch(`${this.target}`, {
        method: "PUT",
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
        body: JSON.stringify(recipe),
      })

      this.getRecipe()
    } catch (error) {
      this.onError && this.onError(error)
    }
  }
}

customElements.define("view-recipe", ViewRecipe)
